import * as React from "react"
import appInsights from '../../../../AppInsights';
import Contact from "../../../route/gracias_en"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Contact props={props} />
      </React.Fragment>
  )
}

export default IndexPage
